// Tooltip.js
import React from 'react';
import './tooltip.css'; // Make sure this CSS file styles your tooltip

const Tooltip = ({ text, x, y }) => {
  return (
    <div
      className="tooltip"
      style={{ left: x, top: y }}
    >
      {text}
    </div>
  );
};

export default Tooltip;