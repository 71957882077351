import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import NpdbLogo from './images/NPDBlogo.png';
import { UserContext } from './UserContext';
import './ParkDetails.css';
import { CloudinaryContext, Image, Transformation } from 'cloudinary-react';

const ParkDetails = () => {
  const { parkCode } = useParams();
  const [park, setPark] = useState(null);
  const [activityColumns, setActivityColumns] = useState([[], [], []]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [wishlist, setWishlist] = useState([]);
  const [isInWishlist, setIsInWishlist] = useState(false);
  const [visitedParks, setVisitedParks] = useState([]);
  const [isVisited, setIsVisited] = useState(false);
  const { user, isAuthenticated } = useContext(UserContext);
  const [reviews, setReviews] = useState([]);
  const [reviewsLoading, setReviewsLoading] = useState(true);
  const [reviewsError, setReviewsError] = useState(null);

  const fetchParkData = useCallback(async () => {
    try {
      setLoading(true);
      const response = await axios.get(`https://new-npdb.onrender.com/api/parks/code/${parkCode}`);
      if (response.data) {
        const parkData = response.data; // Adjust based on actual response structure
        setPark(parkData);
  
        // Distribute activities across 3 columns
        const columns = [[], [], []];
        parkData.activities.forEach((activity, index) => {
          columns[index % 3].push(activity);
        });
        setActivityColumns(columns);
      } else {
        setPark(null);
      }
    } catch (error) {
      setError(error.message || "An error occurred");
    } finally {
      setLoading(false);
    }
  }, [parkCode]);

  const fetchReviews = useCallback(async () => {
    try {
      setReviewsLoading(true);
      const response = await axios.get(`https://new-npdb.onrender.com/api/reviews/parks/${parkCode}`);
      if (response.data && Array.isArray(response.data)) {
        setReviews(response.data);
      } else {
        setReviews([]);
      }
    } catch (error) {
      
      setReviewsError(error.message || "Failed to fetch reviews");
    } finally {
      setReviewsLoading(false);
    }
  }, [parkCode]);

  const fetchVisitedParks = useCallback(async () => {
    if (!isAuthenticated || !user?.id) return;

    try {
      const response = await axios.get(`https://new-npdb.onrender.com/api/visited/${user.id}`);
      if (response.data) {
        setVisitedParks(response.data);
        setIsVisited(response.data.some(item => item.parkCode === parkCode));
      } else {
        setVisitedParks([]);
      }
    } catch (error) {
      setError("Failed to fetch visited parks.");
    }
  }, [isAuthenticated, user?.id, parkCode]);

  const fetchWishlist = useCallback(async () => {
    if (!isAuthenticated || !user?.id) return;

    try {
      const response = await axios.get(`https://new-npdb.onrender.com/api/wishlist/${user.id}`);
      if (response.data) {
        setWishlist(response.data);
        setIsInWishlist(response.data.some(item => item.parkCode === parkCode));
      } else {
        setWishlist([]);
      }
    } catch (error) {
      setError("Failed to fetch wishlist.");
    }
  }, [isAuthenticated, user?.id, parkCode]);

  useEffect(() => {
    fetchParkData();
    fetchWishlist();
    fetchVisitedParks();
    fetchReviews();
  }, [fetchParkData, fetchWishlist, fetchVisitedParks, fetchReviews]);

  const addToVisited = async () => {
    if (!user?.id || !park?.parkCode) return;

    // Check if park is already in visited list
    if (isVisited) {
      alert('Park is already in your visited list.');
      return;
    }

    try {
      const response = await axios.post("https://new-npdb.onrender.com/api/visited", {
        userId: user.id,
        username: user.username,
        parkCode: park.parkCode,
        fullName: park.fullName,
        states: park.states,
      });

      if (response.status === 201) {
        setVisitedParks([...visitedParks, park]);
        setIsVisited(true);
        alert('Park added to visited list');
      }
    } catch (error) {
      console.error("Error adding to visited list:", error.message);
    }
  };

  const addToWishlist = async () => {
    if (!user?.id || !park?.parkCode) return;

    // Check if park is already in wishlist
    if (isInWishlist) {
      alert('Park is already in your wishlist.');
      return;
    }

    try {
      const response = await axios.post("https://new-npdb.onrender.com/api/wishlist", {
        userId: user.id,
        username: user.username,
        parkCode: park.parkCode,
        fullName: park.fullName,
        states: park.states,
        activities: park.activities
      });

      if (response.status === 201) {
        setWishlist([...wishlist, park]);
        setIsInWishlist(true);
        alert('Park added to wishlist');
      }
    } catch (error) {
      console.error("Error adding to wishlist:", error.message);
    }
  };

  const handleHelpfulClick = async (reviewId) => {
    if (!user || !user.id) {
      alert('Must be registered and logged in to mark reviews as helpful.');
      return;
    }
  
    try {
      const response = await axios.post(`https://new-npdb.onrender.com/api/reviews/${reviewId}/helpful`, {
        userId: user.id
      });

  
      setReviews(prevReviews => prevReviews.map(review =>
        review._id === reviewId ? { ...review, helpfulCount: response.data.helpfulCount, usersWhoClicked: [...review.usersWhoClicked, user.id] } : review
      ));
    } catch (error) {
      console.error('Error marking review as helpful:', error);
      alert(error.message);
    }
  };


 const getHelpfulText = (count) => {
    if (count === 0) return '';
    return `${count} reader${count > 1 ? 's' : ''} found this helpful`;
  };

  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <span 
        key={index}
        className={`star ${index < rating ? 'filled' : ''}`}
      >
        ★
      </span>
    ));
  };

  const formatReviewDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    const date = new Date(dateString);
    return `Reviewed on ${date.toLocaleDateString('en-US', options)}`;
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!park) return <div>No park data available</div>;

  return (
    <div className="page-container">
      <div className="small-spacer"></div>
      <div className="container-fluid"></div>
      <h2>{park.fullName}</h2>
      <div
        id="detail-page-header"
        style={{ backgroundImage: `url(${park.images[0]?.url || NpdbLogo})` }}
      ></div>
      <div className="card mb-3">
        <div className="row g-0">
          <div className="col-md-4 d-flex align-items-center">
            {park.images.length > 0 && (
              <img
                className="img-fluid rounded-start"
                id="detail-image"
                src={park.images[1]?.url ? park.images[1]?.url : NpdbLogo}
                alt={park.fullName}
              />
            )}
          </div>
          <div className="col-md-8">
            <div className="card-body">
              <h5 className="card-title">{park.fullName}</h5>
              <p className="card-text">{park.description}</p>
            </div>
          </div>
        </div>
      </div>
      <h3>Activities</h3>
      <div className="activities-container">
        {activityColumns.map((column, columnIndex) => (
          <div key={columnIndex} className="activity-column">
            {column.map((activity, index) => (
              <div key={index} className="activity-item">
                {activity.name}
              </div>
            ))}
          </div>
        ))}
      </div>

      {isAuthenticated ? (
  <>
    <div style={{ textAlign: 'center' }}> {/* Centering container */}
      <p></p>
      {!isInWishlist ? (
        <button
          className="btn btn-primary"
          onClick={addToWishlist}
          style={{ margin: '0 10px' }} // Adds horizontal space between buttons
        >
          Add to Wishlist
        </button>
      ) : (
        <button disabled className="btn btn-success" style={{ margin: '0 10px' }}>
          Added
        </button>
      )}
      {!isVisited ? (
        <button
          className="btn btn-outline-primary"
          style={{ backgroundColor: "white", margin: '0 10px' }} // Adds horizontal space between buttons
          onClick={addToVisited}
        >
          Add to Visited
        </button>
      ) : (
        <button disabled className="btn btn-success" style={{ margin: '0 10px' }}>
          Added
        </button>
      )}
      <p></p>
    </div>
  </>
) : (
  <div style={{ color: "#ccb9aa", textAlign: 'center' }}>
    <p></p>
    <p className="off-the-wall">Please create an account and log in to add parks to your wishlist and visited list.</p>
  </div>
)}
<p></p>

    <h3>Reviews</h3>
          {reviewsLoading ? (
            <div>Loading reviews...</div>
          ) : reviewsError ? (
            <div>{reviewsError}</div>
          ) : reviews.length > 0 ? (
            <div className="reviews-container">
            {reviews.map((review, index) => (
              <div key={index} className="review-card">
                <div className="card-body">
                  <h5 className="card-title">{review.reviewTitle}</h5>
                  <div className="readonly-star-rating2">
                    {renderStars(review.rating)}
                  </div>
                  <CloudinaryContext cloudName="dxrummmws">
                    <Image 
                    publicId={review.profileImage}
                    className="user-review-pic">
                    <Transformation width="80" crop="scale" />
                    </Image>
                  </CloudinaryContext>
                  <p></p>
                  <p className="user-review-name">{review.username}</p>
                  <p className="card-text">{review.review}</p>
              <div>
                <p></p>
                <small className="text-muted">
                {formatReviewDate(review.createdAt)}
                  </small>
                <p></p>
              <button
                  onClick={() => handleHelpfulClick(review._id)}
                  disabled={user && review.usersWhoClicked.includes(user.id)}
                  className={
                    user && review.usersWhoClicked.includes(user.id)
                      ? 'btn btn-success'
                      : 'btn btn-primary'
                  }
                  style={{ margin: '0 10px' }} // Adds horizontal space between buttons
                >
                  {user && review.usersWhoClicked.includes(user.id) ? 'Your feedback is appreciated' : 'Helpful'}
                </button>
                {review.helpfulCount > 0 && <p className='helpfulText'>{getHelpfulText(review.helpfulCount)}</p>}
                </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="off-the-wall2">
          No reviews for this park yet. Reviews can be written on your visited list.
        </div>
      )}
      <div className="mini-spacer"></div>
    </div>
  );
};

export default ParkDetails;
