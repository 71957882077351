// cropImageHelper.js
const getCroppedImg = (imageSrc, croppedAreaPixels) => {
    return new Promise((resolve, reject) => {
      const image = document.createElement('img');
      image.src = imageSrc;
  
      // Wait for the image to load
      image.onload = () => {
        // const { width: imgWidth, height: imgHeight } = image; // Get the image's natural dimensions
        const { x, y, width, height } = croppedAreaPixels;
  
        // Create a canvas for the cropped image
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = width;
        canvas.height = height;
  
        // Draw the image on the canvas
        ctx.drawImage(
          image,
          x, y, width, height,
          0, 0, width, height
        );
  
        // Create another canvas for applying the circular mask
        const circleCanvas = document.createElement('canvas');
        const circleCtx = circleCanvas.getContext('2d');
        circleCanvas.width = width;
        circleCanvas.height = height;
  
        // Apply the circular mask
        circleCtx.beginPath();
        circleCtx.arc(width / 2, height / 2, width / 2, 0, Math.PI * 2, true);
        circleCtx.closePath();
        circleCtx.clip();
  
        // Draw the cropped image onto the circular canvas
        circleCtx.drawImage(canvas, 0, 0);
  
        // Get the base64 encoded image
        const base64Image = circleCanvas.toDataURL('image/png'); // Use 'image/png' for transparency
        resolve(base64Image);
      };
  
      // Handle errors
      image.onerror = (error) => {
        reject(error);
      };
    });
  };
  
  export default getCroppedImg;
  