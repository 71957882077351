import React from "react";
import { useLocation } from "react-router-dom";

const PageNotFound = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const message = query.get('message');
  const error = query.get('error');

  return (
    <div className="page-container">
      <div>
        <img src="./error.png" alt="Page Not Found" />
        {message && <p>{message}</p>}
        {error && <p style={{ color: 'red' }}>{error}</p>}
      </div>
    </div>
  );
};

export default PageNotFound;
