import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './VisitorsTable.css';

const VisitorsTable = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortByVisited, setSortByVisited] = useState(true); // State to manage toggle (default is "Visited")

  useEffect(() => {
    const fetchData = async () => {
      try {
        const usersResponse = await axios.get('https://new-npdb.onrender.com/api/aggregate');
        setUsers(usersResponse.data);
        setLoading(false);
      } catch (error) {
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  // Sorting logic based on toggle switch
  const sortedUsers = [...users].sort((a, b) => {
    if (sortByVisited) {
      return b.totalVisited - a.totalVisited; // Sort by Parks Visited in descending order
    } else {
      return b.totalWishlist - a.totalWishlist; // Sort by Parks on Wishlist in descending order
    }
  });

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="page-container">
      <div className="small-spacer"></div>
      <h1>Park Visitors</h1>
      <div className="mini-spacer"></div>
      {/* Toggle switch for sorting */}
      <div className="toggle-container">
        <label className="switch">
          <input 
            type="checkbox" 
            checked={sortByVisited} 
            onChange={() => setSortByVisited(!sortByVisited)} 
          />
          <span className="slider round"></span>
        </label>
        <span className="toggle-label">{sortByVisited ? 'Sort by Visited' : 'Sort by Wishlist'}</span>
      </div>

      <div className="container shadow table-responsive font-link pt-2">
        <table className="table table-sm table-dark table-bordered">
          <thead className="table-group-divider">
            <tr className="new-color">
              <th>Username</th>
              <th>Parks on Wishlist</th>
              <th>Parks Visited</th>
            </tr>
          </thead>
          <tbody className="new-color">
            {sortedUsers.map(user => (
              <tr key={user.username}>
                <td> 
                  <Link className="link-color-light" to={`/user/${user.username}`}>{user.username}</Link>
                </td>
                <td>{user.totalWishlist}</td>
                <td>{user.totalVisited}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="spacer"></div>
    </div>
  );
};

export default VisitorsTable;
