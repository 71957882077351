import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AlertError from "./AlertError";

const Register = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== passwordConfirm) {
      setErrorMessage("Passwords do not match");
      return;
    }

    const data = { username, password, passwordConfirm, email };

    try {
      const response = await axios.post("https://new-npdb.onrender.com/api/register", data);
      
      // Check for status 201 (user successfully registered)
      if (response.status === 201) {
        alert('Registration successful! Please check your email for confirmation.'); // Show success alert

        // Redirect to the login page after registration
        navigate("/login");
      }
    } catch (error) {
      // Display validation or other errors from the backend
      setErrorMessage(error.response?.data?.errors?.[0]?.msg || "An error occurred");
    }
  };

  return (
    <div className="page-container">
      <div className="mini-spacer"></div>
      <div className="mini-spacer"></div>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h1 className="form-title">Register</h1>
          <div className="mini-spacer"></div>
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              {errorMessage && <AlertError>{errorMessage}</AlertError>}
              <div className="mb-3">
                <label htmlFor="SignUp__username" className="form-label">
                  Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="SignUp__username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="SignUp__email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="SignUp__email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="SignUp__password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="SignUp__password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="SignUp__password_confirmation" className="form-label">
                  Confirm Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="SignUp__password_confirmation"
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e.target.value)}
                  required
                />
              </div>
              <div className="mini-spacer"></div>
              <button
                type="submit"
                className="btn btn-primary"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <div className="mini-spacer"></div>
      <div className="mini-spacer"></div>
    </div>
  );
};

export default Register;
