import React, { useState } from "react";
import { ComposableMap, Geographies, Geography } from "react-simple-maps";
import allStates from "./data/allstates.json";
import Tooltip from "./Tooltip";
import "./map.css"; // Import the CSS file

const geoUrl = "https://cdn.jsdelivr.net/npm/us-atlas@3/states-10m.json";

const MapChart = ({ mapHandler }) => {
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (geo) => {
    const stateName = allStates.find((state) => state.val === geo.id)?.name;
    setTooltipContent(stateName);
  };

  const handleMouseMove = (e) => {
    setTooltipPosition({ x: e.pageX + 10, y: e.pageY + 10 });
  };

  const handleMouseLeave = () => {
    setTooltipContent("");
  };

  return (
    <div className="map-container" onMouseMove={handleMouseMove}>
      <ComposableMap projection="geoAlbersUsa">
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              const stateId = allStates.find(
                (state) => state.val === geo.id
              )?.id;

              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={() => mapHandler(stateId)}
                  className="state"
                  style={{
                    default: { fill: "#E9E3DA", outline: "none" },
                    hover: { fill: "#2A9D8F", outline: "none" },
                    pressed: { fill: "#264653", outline: "none" },
                  }}
                  onMouseEnter={() => handleMouseEnter(geo)}
                  onMouseLeave={handleMouseLeave}
                />
              );
            })
          }
        </Geographies>
      </ComposableMap>
      {tooltipContent && (
        <Tooltip text={tooltipContent} x={tooltipPosition.x} y={tooltipPosition.y} />
      )}
    </div>
  );
};

export default MapChart;
