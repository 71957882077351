import React, { useState, useContext } from "react";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import { UserContext } from "./UserContext";

const Login = () => {
  const { login, isAuthenticated } = useContext(UserContext);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  // Redirect if already authenticated
  if (isAuthenticated) {
    navigate("/"); // Redirect to home if already logged in
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post("https://new-npdb.onrender.com/api/login", {
        username,
        password,
      });
      const { token, user } = response.data;

      if (user && user.username && user.id) {
        localStorage.setItem("token", token);
        login(user); // Set user and authenticate
        navigate("/");
      } else {
        throw new Error("Invalid login response.");
      }
    } catch (err) {
      // Check if the error is due to email not being confirmed
      if (err.response && err.response.data.msg === 'Please confirm your email before logging in.') {
        setError("Please confirm your email before logging in. Check your inbox for the confirmation link.");
      } else {
        setError("Login failed. Please check your username and password.");
      }
    }
};

  return (
    <div className="page-container">
      <div className="mini-spacer"></div>
      <div className="mini-spacer"></div>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h1>Login</h1>
          <div className="mini-spacer"></div>
          <div className="form-container">
            <form onSubmit={handleSubmit}>
              <div className="mb-3">
                <label htmlFor="Login__username" className="form-label">
                  Username
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="Login__username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="Login__password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  className="form-control"
                  id="Login__password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="mini-spacer"></div>
              {error && <div className="alert alert-danger">{error}</div>}
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
            <p></p>
            <div className="mini-spacer"></div>
            <p className="form-label" style={{ textAlign: "center" }}>
              Don't have an account? Register <Link to="/signup">here</Link>.
            </p>

            <p className="form-label" style={{ textAlign: "center" }}>
              <Link to="/forgot-password">Forgot password?</Link>
            </p>
          </div>
        </div>
      </div>
      <div className="spacer"></div>
    </div>
  );
};

export default Login;
