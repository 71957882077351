import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from './UserContext';
import './Visited.css';

function Visited() {
  const [visitedParks, setVisitedParks] = useState([]);
  const [parkImages, setParkImages] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPark, setCurrentPark] = useState(null);
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [reviewTitle, setReviewTitle] = useState('');
  const [review, setReview] = useState('');
  const { user, isAuthenticated } = useContext(UserContext);

  useEffect(() => {
    const fetchVisitedParks = async () => {
      if (!isAuthenticated || !user?.id) {
        setLoading(false);
        return;
      }
  
      try {
        const { id } = user;
        const response = await axios.get(`https://new-npdb.onrender.com/api/visited/${id}`);
  
        if (Array.isArray(response.data)) {
          setVisitedParks(response.data);
  
          // Fetch images for all visited parks in parallel
          const parkImagesData = {};
          const imageFetchPromises = response.data.map(async (park) => {
            try {
              const parkResponse = await axios.get(`https://new-npdb.onrender.com/api/parks/code/${park.parkCode}`);
              if (parkResponse.data && parkResponse.data.images.length > 0) {
                parkImagesData[park.parkCode] = parkResponse.data.images[0]?.url;
              }
            } catch (err) {
              console.error(`Failed to fetch image for park code ${park.parkCode}`);
            }
          });
  
          await Promise.all(imageFetchPromises);
          setParkImages(parkImagesData);
        } else {
          setError('Unexpected response format.');
        }
      } catch (error) {
        setError('Failed to fetch visited parks.');
      } finally {
        setLoading(false);
      }
    };
  
    fetchVisitedParks();
  }, [isAuthenticated, user]);

  const handleDelete = async (parkCode) => {
    const confirmed = window.confirm("Are you sure you want to delete this item?");
    if (!confirmed) return;

    if (!user) {
      setError('User not logged in.');
      return;
    }

    try {
      await axios.delete(`https://new-npdb.onrender.com/api/visited/${user.id}/${parkCode}`);
      setVisitedParks(visitedParks.filter((park) => park.parkCode !== parkCode));
    } catch (error) {
      setError(`Failed to delete park with code ${parkCode}.`);
    }
  };

  const handleAddReview = (park) => {
    setCurrentPark(park);
    setIsModalOpen(true);

    // Check if there is an existing review for the current park
    const existingReview = park.reviews?.find(review => review.userId === user.id);
    
    setRating(existingReview ? existingReview.rating : 0); // Set existing rating if available
    setHoverRating(0); // Reset hover rating
    setReviewTitle(existingReview ? existingReview.title : '');
    setReview(existingReview ? existingReview.text : '');
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCurrentPark(null);
    setRating(0);
    setReviewTitle('');
    setReview('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (rating === 0) {
      alert('Please select number of stars for your review.');
      return;
    }

    try {
      await axios.post(`https://new-npdb.onrender.com/api/reviews`, {
        parkCode: currentPark.parkCode,
        reviewTitle,
        review,
        rating,
        userId: user.id,
        username: user.username,
        fullName: currentPark.fullName,
        profileImage: user.profileImage,
      });

      alert('Review submitted successfully!');
      handleModalClose();
    } catch (error) {
      alert('Failed to submit review.');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <>
      <div className="page-container">
        <div className="small-spacer"></div>
        <h1>My Visited Parks</h1>
        <p className="table-sentence">Click on Park Name to see details about the park.</p>
        <div className="container shadow table-responsive font-link pt-2">
          {visitedParks.length === 0 ? (
            <div className="off-the-wall2">No parks added to Visited List yet.</div>
          ) : (
            <table className="table table-sm table-dark table-bordered">
              <thead className="table-group-divider">
                <tr className="new-color">
                  <th></th>
                  <th>Park Name</th>
                  <th>Location</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="new-color">
                {visitedParks.map((park) => (
                  <tr className="object-fit" key={park._id}>
                    <td>
                      {parkImages[park.parkCode] ? (
                        <img
                          src={parkImages[park.parkCode]}
                          alt={park.fullName}
                          style={{ height: '100px', width: 'auto' }}
                        />
                      ) : (
                        <div>No Image</div>
                      )}
                    </td>
                    <td>
                      <Link
                        to={`/park/${park.parkCode}`}
                        className="link-dark"
                        style={{ textDecoration: 'none' }}
                      >
                        {park.fullName}
                      </Link>
                    </td>
                    <td>{park.states}</td>
                    <td>
                      <button
                        className="btn shadow btn-primary"
                        onClick={() => handleAddReview(park)}
                      >
                        Add Review
                      </button>
                    </td>
                    <td>
                      <button
                        className="btn btn-outline-primary"
                        style={{ backgroundColor: 'white' }}
                        onClick={() => handleDelete(park.parkCode)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
        <div className="small-spacer"></div>
      </div>

      {isModalOpen && currentPark && (
  <div className="modal-overlay">
    <div className="modal-content">
      <span className="close" onClick={handleModalClose}>&times;</span>
      <h2>Review: {currentPark.fullName}</h2>
      <form onSubmit={handleSubmit}>
        {/* Star Rating Section */}
        <div className="rating-container">
          {[...Array(5)].map((_, index) => {
            const starIndex = index + 1;
            return (
              <span
                key={starIndex}
                className={`star ${starIndex <= (hoverRating || rating) ? 'star-filled' : ''}`}
                onClick={() => setRating(starIndex)}
                onMouseEnter={() => setHoverRating(starIndex)}
                onMouseLeave={() => setHoverRating(0)} // Revert to selected rating after hovering ends
              >
                &#9733;
              </span>
            );
          })}
        </div>

        {/* Review Title and Text Fields */}
        <input
          type="text"
          className="review-title"
          value={reviewTitle}
          onChange={(e) => setReviewTitle(e.target.value)}
          placeholder="Review Title"
          maxLength="50"
        />
        <div className="character-count-title2">{reviewTitle.length}/50</div>
        
        <textarea
          className="review-text"
          value={review}
          onChange={(e) => setReview(e.target.value)}
          placeholder="Write your review here..."
          maxLength="500"
        />
        <div className="character-count">{review.length}/500</div>

        <button className="submit-review-btn" type="submit">Submit</button>
      </form>
    </div>
  </div>
)}
    </>
  );
}

export default Visited;
