import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EditReviewForm.css'; // Import the CSS file for styling

const EditReviewForm = ({ reviewId, closeModal }) => {
  const [reviewTitle, setReviewTitle] = useState('');
  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReviewDetails = async () => {
      try {
        const response = await axios.get(`https://new-npdb.onrender.com/api/reviews/${reviewId}`);
        setReviewTitle(response.data.reviewTitle);
        setReview(response.data.review);
        setRating(response.data.rating);
      } catch (error) {
        console.error('Error fetching review details:', error);
        setError('Failed to load review details.');
      }
    };

    fetchReviewDetails();
  }, [reviewId]);

  const handleUpdateReview = async (e) => {
    e.preventDefault();
    try {
      await axios.put(`https://new-npdb.onrender.com/api/reviews/${reviewId}`, {
        reviewTitle,
        review,
        rating,
      });
      closeModal(); // Close the modal after successful update
    } catch (error) {
      console.error('Error updating review:', error);
      setError('Failed to update review.');
    }
  };

  const handleStarClick = (index) => {
    setRating(index + 1);
  };

  const renderStars = (currentRating) => {
    return [...Array(5)].map((_, index) => (
      <span
        key={index}
        className={`star ${index < currentRating ? 'filled' : ''}`}
        onClick={() => handleStarClick(index)}
      >
        ★
      </span>
    ));
  };

  return (
    <div className="modal-overlay" onClick={closeModal}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <span className="close" onClick={closeModal}>&times;</span>
        <h2>Edit Review</h2>
        <form onSubmit={handleUpdateReview}>
          {error && <div className="error-message">{error}</div>}
          <div className="form-group">
            <label htmlFor="reviewTitle">Title:</label>
            <input
              type="text"
              id="reviewTitle"
              value={reviewTitle}
              onChange={(e) => setReviewTitle(e.target.value)}
              maxLength={50}
              required
            />
            <div className="character-count-title">
              {reviewTitle.length} / 50
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="review">Review:</label>
            <textarea
              id="review"
              value={review}
              onChange={(e) => setReview(e.target.value)}
              maxLength={500}
              required
            />
            <div className="character-count-review">
              {review.length} / 500
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="rating">Rating:</label>
            <div className="star-rating">
              {renderStars(rating)}
            </div>
          </div>
          <button type="submit">Update Review</button>
        </form>
      </div>
    </div>
  );
};

export default EditReviewForm;
