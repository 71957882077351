import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ConfirmEmail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [status, setStatus] = useState('Confirming email...'); // Track confirmation status

  useEffect(() => {
    const confirmEmail = async () => {
      const token = new URLSearchParams(location.search).get('token');
      if (!token) {
        setStatus('Invalid or missing token. Redirecting to login...');
        setTimeout(() => navigate('/login'), 3000);
        return;
      }

      try {
        const response = await fetch(`https://new-npdb.onrender.com/api/confirm-email?token=${token}`);
        const data = await response.json();
        if (!response.ok) {
          setStatus(`Email confirmation failed: ${data.message || 'Unknown error'}`);
          setTimeout(() => navigate('/login'), 3000);
          return;
        }

        setStatus('Email confirmed successfully! Redirecting to login...');
        setTimeout(() => navigate('/login'), 3000);
      } catch (error) {
        console.error('Error confirming email:', error);
        setStatus('An error occurred. Redirecting to login...');
        setTimeout(() => navigate('/login'), 3000);
      }
    };

    confirmEmail();
  }, [location.search, navigate]);

  return <div>{status}</div>; // Display status to user
};

export default ConfirmEmail;
