import React from "react";
import { useNavigate } from "react-router-dom";
import MapChart from "./MapChart";
import "./map.css";
import { Link } from "react-router-dom";

const HomePage = () => {
  const navigate = useNavigate();

  const mapHandler = (stateId) => {
    if (stateId) {
      navigate(`/parks/${stateId}`);
    }
  }




  return (
    <div
      className="container-fluid"
      style={{
        backgroundImage: `url("https://vastphotos.com/files/uploads/photos/11076/high-resolution-yellowstone-photo-l.jpg?v=20220712073521")`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div className="col-sm-6 flex-grow-1 justify-content-center">
        <MapChart mapHandler={mapHandler} /> 
      </div>
      <div className="col flex-shrink-1 justify-content-center">
        <div className="card text-dark bg-light mb-3">
          <div id="welcome-card-header" className="card-header">
            <strong>Welcome to NPDB</strong>
          </div>
          <div id="welcome-card-body" className="card-body">
            <h5 className="card-title" style={{textAlign: "center"}}>National Park Database</h5> 
            <div style={{ textAlign: 'center' }}>
            <p className="card-text">
              Click a state to see all parks in that state.
              </p>
          
              
              <p></p>
              <Link to="/most-wishlisted" className="btn shadow btn-outline-primary" style={{ margin: '10px' }}>
                Most Wishlisted National Parks
              </Link>
              
              <Link to="/most-visited" className="btn shadow btn-outline-primary" style={{ margin: '10px' }}>
                The Most Visited National Parks
              </Link>
              <p></p>
              </div>
              <p className="card-text">
              Register to track visits, make a wishlist, and write reviews.
              </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;