import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from './UserContext';
import EditReviewForm from './EditReviewForm';
import { CloudinaryContext, Image, Transformation } from 'cloudinary-react';
import CropModal from './CropModal';

const MyProfile = () => {
  // State definitions
  const [reviews, setReviews] = useState([]);
  const [editingReviewId, setEditingReviewId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { user, login } = useContext(UserContext); // Use login instead of setUser
  const [wishlist, setWishlist] = useState([]);
  const [visitedParks, setVisitedParks] = useState([]);
  const [wishlistLoading, setWishlistLoading] = useState(true);
  const [visitedLoading, setVisitedLoading] = useState(true);
  const [parkImages, setParkImages] = useState({});
  const [file, setFile] = useState(null);
  const [imageSrc, setImageSrc] = useState(null);
  const [isCropModalOpen, setIsCropModalOpen] = useState(false);
  const MAX_IMAGE_SIZE = 300 * 1024; // 300 KB
  

  // Fetch reviews
  const fetchReviews = useCallback(async () => {
    if (!user) {
      return;
    }

    try {
      const response = await axios.get(`https://new-npdb.onrender.com/api/reviews/user/${user.id}`);
      setReviews(response.data.data);
    } catch (error) {
      console.error('Error fetching reviews:', error);
    }
  }, [user]);

  useEffect(() => {
    fetchReviews();
  }, [fetchReviews]);

  // Fetch wishlist
  const fetchWishlist = async () => {
    if (!user) return;
    setWishlistLoading(true);
    try {
      const response = await axios.get(`https://new-npdb.onrender.com/api/wishlist/${user.id}`);
      setWishlist(response.data);
    } catch (error) {
      console.error('Error fetching wishlist:', error);
    } finally {
      setWishlistLoading(false);
    }
  };

  // Fetch visited parks
  const fetchVisitedParks = async () => {
    if (!user) return;
    setVisitedLoading(true);
    try {
      const response = await axios.get(`https://new-npdb.onrender.com/api/visited/${user.id}`);
      setVisitedParks(response.data);
    } catch (error) {
      console.error('Error fetching visited parks:', error);
    } finally {
      setVisitedLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchWishlist();
      await fetchVisitedParks();
    };

    if (user) {
      fetchData();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  // Fetch park images
  const fetchParkImages = async (parkCodes) => {
    try {
      const parkImagesData = {};
      for (const parkCode of parkCodes) {
        const parkResponse = await axios.get(`https://new-npdb.onrender.com/api/parks/code/${parkCode}`);
        if (parkResponse.data && parkResponse.data.images.length > 0) {
          parkImagesData[parkCode] = parkResponse.data.images[0]?.url;
        }
      }
      setParkImages(parkImagesData);
    } catch (error) {
      console.error('Error fetching park images:', error);
    }
  };

  useEffect(() => {
    const allParkCodes = [
      ...wishlist.map(item => item.parkCode),
      ...visitedParks.map(park => park.parkCode)
    ];

    if (allParkCodes.length > 0) {
      fetchParkImages(allParkCodes);
    }
  }, [wishlist, visitedParks]);

  // Delete review
  const handleDeleteReview = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this review?');

    if (!confirmed) {
      return;
    }

    try {
      await axios.delete(`https://new-npdb.onrender.com/api/reviews/${id}`);
      setReviews(reviews.filter((review) => review._id !== id));
    } catch (error) {
      console.error('Error deleting review:', error);
    }
  };

  // Fetch profile image
  // eslint-disable-next-line no-unused-vars
  const fetchProfileImage = async () => {
    try {
      const response = await axios.get(`https://new-npdb.onrender.com/api/users/${user.id}`);
      const profileImage = response.data.user.profileImage;
      return profileImage ? profileImage.replace('/uploads/', '') : 'default-profile_torudua';
    } catch (error) {
      console.error('Error fetching profile image:', error);
      return 'default-profile_torudua'; // Fallback in case of error
    }
  };

  // Handle file change
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    console.log('File selected:', file);

    if (file.size > MAX_IMAGE_SIZE) {
      alert('File size exceeds the limit of 300 KB.');
      return;
    }

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log('File loaded');
        setImageSrc(reader.result);
        setIsCropModalOpen(true);
      };
      reader.readAsDataURL(file);
      setFile(file);
    }
  };

  const handleCropComplete = async (croppedImgBase64) => {
    console.log('Cropping completed, base64:', croppedImgBase64);
  
    // Convert base64 to a blob
    const response = await fetch(croppedImgBase64);
    const blob = await response.blob();
  
    // Determine the MIME type based on the base64 string (should be "image/jpeg" or "image/png")
    const mimeType = croppedImgBase64.includes("image/jpeg") ? "image/jpeg" : "image/png";
  
    // Create a file from the blob and set the MIME type correctly
    const file = new File([blob], 'profile-image.jpg', { type: mimeType });
    console.log('Cropped file:', file);
  
    setFile(file);
    setIsCropModalOpen(false);
  };

  // Handle upload
  const handleUpload = async () => {
    if (!file) {
      alert('Please select a file to upload.');
      return;
    }

    const formData = new FormData();
    formData.append('profileImage', file);  // Match the key expected by the server
    formData.append('userId', user.id);

    console.log('Uploading file:', file);

    try {
      const response = await axios.post('https://new-npdb.onrender.com/api/users/upload-profile-image', formData);
      console.log('Upload response:', response);
      const updatedProfileImage = response.data.user.profileImage;  // Ensure you're accessing the correct field

      // Use login to update the user context
      login({
        ...user,
        profileImage: updatedProfileImage
      });
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Failed to upload file.');
    }
  };

  // Render star ratings
  const renderStars = (rating) => {
    return [...Array(5)].map((_, index) => (
      <span
        key={index}
        className={`star ${index < rating ? 'filled' : ''}`}
      >
        ★
      </span>
    ));
  };

  return (
    <>
      <div className="page-container">
        <div className="small-spacer"></div>
        <h1>My Profile: {user.username}</h1>
        <CloudinaryContext cloudName="dxrummmws">
          <Image
            publicId={user.profileImage ? user.profileImage.replace('/uploads/', '') : 'default-profile_torudua'}
            alt="Profile Image"
            className="profile-image"
          >
            <Transformation width="120" crop="scale" />
          </Image>
        </CloudinaryContext>
        <p></p>
        
        <div className="input-button-container">
          <input
            type="file"
            accept="image/*"
            className="btn btn-primary"
            onChange={handleFileChange}
          />
          <button className="btn btn-outline-primary" onClick={handleUpload}>
            Upload
          </button>
        </div>
       <p></p>
        <h2>My Reviews</h2>
        <div className="container shadow table-responsive font-link pt-2">
          <table className="table table-sm table-dark table-bordered">
            <thead className="table-group-divider">
              <tr className="new-color">
                <th>Park Name</th>
                <th>Rating</th>
                <th>Review</th>
                <th>Edit</th>
                <th>Delete</th>
              </tr>
            </thead>
            <tbody className="new-color">
              {reviews.map((review) => (
                <tr key={review._id}>
                  <td>
                    <Link to={`/park/${review.parkCode}`} className="link-dark" style={{ textDecoration: 'none' }}>
                      {review.fullName}
                    </Link>
                  </td>
                  <td>
                    <div className="readonly-star-rating">{renderStars(review.rating)}</div>
                  </td>
                  <td>{review.review}</td>
                  <td>
                    <button
                      className="btn shadow btn-primary"
                      onClick={() => {
                        setEditingReviewId(review._id);
                        setModalOpen(true);
                      }}
                    >
                      Edit
                    </button>
                  </td>
                  <td>
                    <button className="btn btn-outline-primary" onClick={() => handleDeleteReview(review._id)}>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

        {/* Wishlist Section */}
        <div className="small-spacer"></div>
        <h2>My Wishlist</h2>
        <p className="table-sentence">Click on Park Name to see details about the park.</p>
        <p></p>
        {wishlistLoading ? (
          <div>Loading wishlist...</div>
        ) : (
          <div className="container shadow table-responsive font-link pt-2">
            <table className="table table-sm table-dark table-bordered">
              <thead className="table-group-divider">
                <tr className="new-color">
                  <th></th>
                  <th>Park Name</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody className="new-color">
                {wishlist.map((item) => (
                  <tr key={item._id}>
                    <td>
                      {parkImages[item.parkCode] ? (
                        <img
                          src={parkImages[item.parkCode]}
                          alt={item.fullName}
                          style={{ height: '100px', width: 'auto' }}
                        />
                      ) : (
                        <div>No Image</div>
                      )}
                    </td>
                    <td>
                      <Link to={`/park/${item.parkCode}`} className="link-dark" style={{ textDecoration: 'none' }}>
                        {item.fullName}
                      </Link>
                    </td>
                    <td>{item.states || 'Unknown Location'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        
       {/* Visited Parks Section */}
       <div className="small-spacer"></div>
        <h2>Visited Parks</h2>
        {visitedLoading ? (
          <div>Loading visited parks...</div>
        ) : (
          <div className="container shadow table-responsive font-link pt-2">
            <table className="table table-sm table-dark table-bordered">
              <thead className="table-group-divider">
                <tr className="new-color">
                  <th></th>
                  <th>Park Name</th>
                  <th>Location</th>
                </tr>
              </thead>
              <tbody className="new-color">
                {visitedParks.map((park) => (
                  <tr key={park._id}>
                    <td>
                      {parkImages[park.parkCode] ? (
                        <img src={parkImages[park.parkCode]} alt={park.fullName} style={{ height: '100px', width: 'auto' }} />
                      ) : (
                        <div>No Image</div>
                      )}
                    </td>
                    <td>
                      <Link to={`/park/${park.parkCode}`} className="link-dark" style={{ textDecoration: 'none' }}>
                        {park.fullName}
                      </Link>
                    </td>
                    <td>{park.states || 'Unknown Location'}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
        <div className="small-spacer"></div>
      </div>

      {/* Modal for Edit Review */}
      {modalOpen && editingReviewId && (
        <div className="reviews-modal-overlay">
          <div className="reviews-modal-content">
            <span className="reviews-modal-close" onClick={() => setModalOpen(false)}>&times;</span>
            <h2>Edit Your Review</h2>
            <EditReviewForm
              reviewId={editingReviewId}
              closeModal={() => {
                setModalOpen(false);
                fetchReviews(); // Re-fetch reviews after editing
              }}
            />  
          </div>
        </div>
      )}

      {isCropModalOpen && (
        <CropModal
          imageSrc={imageSrc}
          onCropComplete={handleCropComplete}
          onClose={() => setIsCropModalOpen(false)}
        />
      )}
    </>
  );
};

export default MyProfile;
