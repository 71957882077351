import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./HomePage";
import Nav from "./Nav";
import Register from "./Register";
import Login from "./Login";
import Wishlist from "./Wishlist";
import Visited from "./Visited";
import MyProfile from "./MyProfile";
import NotFoundPage from "./NotFoundPage";
import ParkDetails from "./ParkDetails";
import ParksByStateList from "./ParksByStateList";
import Footer from "./Footer";
import EditReviewForm from "./EditReviewForm";
import VisitorsTable from "./VisitorsTable";
import PublicProfile from "./PublicProfile";
import ConfirmEmail from "./ConfirmEmail";
import MostWishlisted from "./MostWishlisted";
import MostVisited from "./MostVisited";


function App() {
  // const domain = /https:\/\/[^/]+/;
  // const basename = process.env.PUBLIC_URL.replace(domain, "");
  return (
    // <BrowserRouter basename={basename}>
    <BrowserRouter>
      <Nav />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signup" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/wishlist" element={<Wishlist />} />
        <Route path="/visited" element={<Visited />} />
        <Route path="/reviews/mine" element={<MyProfile />} />
        <Route path="/reviews/:code/reviews" element={<MyProfile />} />
        <Route path="*" element={<NotFoundPage />} />
        <Route path="/park/:parkCode" element={<ParkDetails />} />
        <Route path="/parks/:state" element={<ParksByStateList />} />
        <Route path="/edit/:reviewId" element={<EditReviewForm />} />
        <Route path="/visitors" element={<VisitorsTable />} />
        <Route path="/user/:username" element={<PublicProfile />} />
        <Route path="/confirm-email" element={<ConfirmEmail />} />
        <Route path="/most-wishlisted" element={<MostWishlisted />} />
        <Route path="/most-visited" element={<MostVisited />} />

      </Routes>
      <Footer />
    </BrowserRouter>
  );
}
export default App;
