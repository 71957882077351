import React from "react";
import "./index.css";
import Logo from "./images/NPSlogo.png";
import NPDB from "./images/NPDBlogo125.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-content">
         
          <ul className="list">
            <li>
              <img src={Logo} alt="National Park Service Logo" />
            </li>
            <li><small>NPdB is made possible by</small></li>
            <li><small>National Park Service API</small></li>
            <li><small>
              <a
                href="https://www.nps.gov/subjects/developer/index.htm"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.nps.gov/index.htm/
              </a></small>
            </li>
            <li><p></p></li>
          </ul>

          <div className="copyright">
            <ul className="list">
              <li>
                <Link to="/"><img src={NPDB} alt="National Park Database Logo" /></Link>
              </li>
            <li><p></p></li>
            <li><p>&copy; {new Date().getFullYear()} National Park Database</p></li>
            <li><p></p></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;