import React, { useState, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImageHelper'; // Ensure this function is correctly implemented
import './CropModal.css'; // Ensure this file contains correct styles

const CropModal = ({ imageSrc, onClose, onCropComplete }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropChange = useCallback((crop) => {
    setCrop(crop);
  }, []);

  const onCropCompleteCallback = useCallback((_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onConfirmCrop = async () => {
    if (!croppedAreaPixels) {
      console.error('No crop area selected');
      return;
    }
    try {
      // Get cropped image base64 string
      const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
      onCropComplete(croppedImage); // Pass the base64 string to parent component
    } catch (e) {
      console.error('Error cropping image:', e);
    }
  };

  return (
    <div className="crop-modal">
      <div className="crop-container">
        <Cropper
          image={imageSrc}
          crop={crop}
          zoom={zoom}
          aspect={1} // Maintain square aspect ratio for circle
          cropShape="round" // Circular crop
          showGrid={true}
          onCropChange={onCropChange}
          onZoomChange={setZoom}
          onCropComplete={onCropCompleteCallback}
        />
      </div>
      <button className="crop-confirm-button" onClick={onConfirmCrop}>Crop</button>
      <button className="crop-cancel-button" onClick={onClose}>Cancel</button>
    </div>
  );
};

export default CropModal;
